@import "../../sass/abstracts.scss";

.about-me {
  width: 40rem;
  height: 12rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  p {
    -webkit-font-smoothing: antialiased;
    font-size: 1.8rem;
    color: $color-grey-dark;
    font-weight: 500;
    letter-spacing: 0.08rem;
    line-height: 1.66667;
    position: absolute;
    left: 0;
    width: 80%;
    top: 1rem;
  }
}
