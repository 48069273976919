*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0.028rem;
  -webkit-font-smoothing: antialiased !important;
  color: $font-color;
}

html {
  font-size: 62.5% !important; // 1rem = 10px
}

body {
  font-size: 1.4rem;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

a {
  text-decoration: none;
}
