@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
$font-family: "DM Sans", sans-serif;

// COLORS
$color-white: white;
$color-white-op-60: rgba(255, 255, 255, 0.6);
$color-white-op-50: rgba(255, 255, 255, 0.5);
$color-white-op-20: rgba(255, 255, 255, 0.2);
$color-white-op-10: rgba(255, 255, 255, 0.1);

$color-black: hsla(0, 0%, 13%, 1);
$color-black-op-80: hsla(0, 0%, 13%, 0.8);
$color-black-op-50: hsla(0, 0%, 13%, 0.5);

$color-primary: hsla(189, 71%, 40%, 1);
$color-primary-op-50: hsla(189, 71%, 40%, 0.5);
$color-primary-op-70: hsla(189, 71%, 40%, 0.7);
$color-secondary: hsla(230, 10%, 30%, 1);

$color-grey: hsla(220, 8%, 23%, 1);
$color-grey-dark: hsla(230, 11%, 19%, 1);
$color-grey-light: hsla(0, 0%, 44%, 1);

$btn-focus-shadow: 0 0 0 1px $color-grey-light, 0 0 0 3px $color-white-op-50;
$inpt-focus-shadow: 0 0 0 1px $color-grey-light, 0 0 0 3px $color-white-op-50;

// soft colors
$bg-color: hsla(205, 69%, 94%, 1);
$font-color: hsla(194, 34%, 31%, 1);
$light-color: hsla(220, 60%, 99%, 1);
$shadow-color: hsla(207, 49%, 82%, 1);
