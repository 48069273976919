@import "../../../../sass/abstracts.scss";

.home-left {
  // background-color: $color-primary;
  background-color: $color-black;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 6rem;
  justify-content: flex-end;

  &__hero {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    opacity: 1;

    &__wrapper {
      .home-hero {
        width: 30rem;
        height: 30rem;
        position: relative;

        .cls-4 {
          fill: $color-white;
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-color: white;
          opacity: 1;
          transition: opacity 0.3s ease-in-out;
        }

        &:hover {
          &::before {
            opacity: 0;
          }
        }
      }
    }

  }

  &__name {
    margin-bottom: 1rem;
  
    span {
      font-size: calc(0.7vw + 18px);
      font-weight: 600;
      color: $color-primary;
      letter-spacing: 1px;
      color: white;
    }

    p {
      font-size: 2rem;
      font-weight: 400;
      color: $color-primary;
      letter-spacing: 2px;
      color: white;
    }
  }

  &__description {
    font-size: calc(0.25vw + 12px);
    font-weight: 400;
    opacity: 0.8;
    line-height: 1;
    margin-top: 1rem;
    color: white;
    user-select: none;

    p {
      font-size: calc(0.25vw + 12px);
      font-weight: 400;
      opacity: 0.5;
      color: white;
      margin-top: 0.4rem;
      font-style: italic;
    }
  }

  * {
    &::-moz-selection {
      color: $color-primary;
      background: white;
    }

    &::selection {
      color: $color-primary;
      background: white;
    }
  }
}


@media only screen and (max-width: 848px) {
  .home-left {
    padding: 3rem;
  }
}
