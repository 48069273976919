@import "../../../../sass/abstracts.scss";

$after-size: 24rem;

.home-right {
  background-color: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 6rem;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: calc(46% - $after-size / 2);
    left: calc(100% - $after-size / 2);
    width: $after-size;
    height: $after-size;
    border-radius: 50%;
    // background-color: $color-primary;
    background-color: $color-black;
    z-index: -1;
    opacity: 1;
  }

  &__header {
    font-size: calc(0.6vw + 16px);
    line-height: 1.2;
    letter-spacing: 1px;
    display: flex;
    justify-content: space-between;
  }

  &__projects {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    margin: 10rem 0;
  }

  &__project-link {
    font-size: calc(0.6vw + 14px);

    &:hover {
      text-decoration: underline;
    }
  }


  * {
    &::-moz-selection {
      color: white;
      background: $color-primary;
    }

    &::selection {
      color: white;
      background: $color-primary;
    }
  }

  .bottom-nav {
    padding: 3rem 0 0;
    max-width: 30rem;
    height: 7rem;
  }
}


@media only screen and (max-width: 848px) {
  .home-right {
    padding: 3rem;
  }
}
