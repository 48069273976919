@keyframes loadingIncrease {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(0.95);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.animation-scale-up {
  animation: scaleUp 0.5s forwards;
}

@keyframes scaleDown {
  0% {
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.animation-scale-down {
  animation: scaleDown 0.5s forwards;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-5%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animation-slide-down {
  animation: slideDown 0.8s forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.animation-fade-out {
  animation: fadeOut 0.3s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animation-fade-in {
  animation: fadeIn 0.5s forwards;
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(5%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animation-slide-up {
  animation: slideUp 0.8s forwards;
}

@keyframes blurDown {
  0% {
    filter: blur(15px);
  }

  100% {
    filter: blur(0);
  }
}

@keyframes blurUpDown {
  0% {
    filter: blur(0);
  }

  50% {
    filter: blur(15px);
  }

  100% {
    filter: blur(0);
  }
}

@keyframes scaleUpDown {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(0.8);
  }
}

// Transition group animations
.scale-down-appear {
  opacity: 0;
  transform: scale(1.1);
}

.scale-down-appear-active {
  opacity: 1;
  transform: scale(1);
  transition: all 600ms ease-out;
}

.scale-down-enter {
  opacity: 0;
  transform: scale(1.1);
}

.scale-down-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 300ms ease-out 300ms;
}

.scale-down-exit {
  transform: scale(1);
  opacity: 1;
}

.scale-down-exit-active {
  opacity: 0;
  transform: scale(1.1);
  transition: all 300ms ease-out;
}

// Transition group animations
.slide-up-appear {
  opacity: 0;
  transform: translateY(20px);
}

.slide-up-appear-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 600ms ease-out;
}

.slide-up-enter {
  opacity: 0;
  transform: translateY(20px);
}

.slide-up-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 300ms ease-out 300ms;
}

.slide-up-exit {
  transform: scale(1) translateY(0);
  opacity: 1;
}

.slide-up-exit-active {
  opacity: 0;
  transform: scale(0.9) translateY(-100px);
  transition: all 300ms ease-out;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 0.1;
  }

  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 0.2;
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 0.1;
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 0.1;
  }

  25% {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 0.2;
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 0.1;
  }
}

/* slide enter */
.slide-enter {
  opacity: 0;
  transform: scale(1) translateY(15px);
  z-index: 1;
}

.slide-enter.slide-enter-active {
  opacity: 1;
  transform: scale(1) translateY(0);
  transition: opacity 600ms linear 200ms, transform 600ms ease-in-out 200ms;
}

/* slide exit */
.slide-exit {
  opacity: 1;
  transform: scale(1) translateY(0);
}

.slide-exit.slide-exit-active {
  opacity: 0;
  transform: scale(0.97) translateY(-15px);
  transition: opacity 300ms linear, transform 300ms ease-out;
}

.slide-exit-done {
  opacity: 0;
}
